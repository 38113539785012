
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 1000px 0;
  }
}

.Timeline-loader {
  padding: 15px;
}

.loader {
  height: 80px;
  margin: 15px;
  background-image: linear-gradient(90deg,
  #e4e4e4 0%,
  #f1f1f1 40%,
  #ededed 60%,
  #e4e4e4 100%);
  background-position: 0px 0px;
  background-repeat: repeat;
  animation: animatedBackground 5s linear infinite;
}
